import layout from "./layout";
import navbar from "./navbar";
import auth from "./auth/authReducer";
import card from "./cards/cardReducer";
import event from "./event/eventReducer";
import cashier from "./user/userReducer";
import tickets from "./tickets/TicketReducer";
import kitchen from "./kitchen/kitchenReducer";
import profile from "./profile/profileReducer";
import product from "./product/productReducer";
import order from "./orders/ordersListReducer";
import transaction from "./transaction/reducers";
import adminlist from "./admin/adminListReducer";
import terminal from "./terminal/TerminalReducer";
import password from "./password/passwordReducer";
import category from "./category/categoryReducer";
import location from "./location/locationReducer";
import saleStats from "./saleStats/SalesStatsReducer";
import tool from "./correctiontool/CorrectionToolReducer";
import freezeOrder from "./freezeorder/FreezeOrderReducer";
import createRFID from './createRfids/RfidReducer'
import shift from './shift/shiftReducer'
import table from './table/tableReducer'
import accessories from './accessories/accessoriesReducer'
import smartgate from './smartgate/smartGateReducer'

const rootReducer = {
  adminlist,
  auth,
  card,
  category,
  tool,
  event,
  freezeOrder,
  kitchen,
  location,
  order,
  password,
  product,
  profile,
  saleStats,
  terminal,
  tickets,
  transaction,
  cashier,
  layout,
  navbar,
  createRFID,
  shift,
  table,
  accessories,
  smartgate
};
export default rootReducer;
