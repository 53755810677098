import { useEffect } from "react";
import { X } from "react-feather";
import { NavLink } from "react-router-dom";
import themeConfig from "@configs/themeConfig";
import { Sidelift } from "../../../../../assets/images/sidebar";
import { getUserData, getHomeRouteForLoggedInUser } from "@utils";
import Logo from "../../../../../assets/images/logo/logo-winterfever.png"

const VerticalMenuHeader = (props) => {
  const { menuCollapsed, setMenuCollapsed, setGroupOpen, menuHover, setMenuVisibility, } = props;

  const user = getUserData();

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([]);
  }, [menuHover, menuCollapsed]);
  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <span
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(true)}
        >
          <Sidelift />
        </span>
      );
    } else {
      return (
        <span
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(false)}
        >
          <Sidelift />
        </span>
      );
    }
  };
  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle cursor-pointer">
            <Toggler />
          </div>
        </li>
        <NavLink
          to={user ? getHomeRouteForLoggedInUser(user.role) : "/"}
          className="navbar-brand"
        >
          <li className="brand-logo nav-item me-auto">
            <img
              className="navbar-brand active"
              src={Logo}
              alt="logo"
            />
          </li>
        </NavLink>
        <li className="nav-item nav-toggle">
          <div
            className="nav-link modern-nav-toggle cursor-pointer"
            style={{ marginLeft: "50px" }}
          >
            <X
              onClick={() => setMenuVisibility(false)}
              className="toggle-icon icon-x d-block d-xl-none"
              size={20}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default VerticalMenuHeader;
