export const LIST_CREATE_RFID_INITIATED = "LIST_CREATE_RFID_INITIATED";
export const LIST_CREATE_RFID_SUCCESS = "LIST_CREATE_RFID_SUCCESS";
export const LIST_CREATE_RFID_FAILED = "LIST_CREATE_RFID_FAILED";

// ** Create RFID
export const CREATE_RFID_INITIATED = "CREATE_RFID_INITIATED";
export const CREATE_RFID_SUCCESS = "CREATE_RFID_SUCCESS";
export const CREATE_RFID_FAILED = "CREATE_RFID_FAILED";

// ** update
export const UPDATE_CREATE_RFID_INITIATED = "UPDATE_CREATE_RFID_INITIATED";
export const UPDATE_CREATE_RFID_SUCCESS = "UPDATE_CREATE_RFID_SUCCESS";
export const UPDATE_CREATE_RFID_FAILED = "UPDATE_CREATE_RFID_FAILED";

// **  Delete
export const DELETE_CREATE_RFID_INITIATED = "DELETE_CREATE_RFID_INITIATED";
export const DELETE_CREATE_RFID_SUCCESS = "DELETE_CREATE_RFID_SUCCESS";
export const DELETE_CREATE_RFID_FAILED = "DELETE_CREATE_RFID_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
