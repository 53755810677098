import { isObjEmpty } from "@utils";
import { Fragment, lazy } from "react";
import { Navigate } from "react-router-dom";
import BlankLayout from "@layouts/BlankLayout";
import PublicRoute from "@components/routes/PublicRoute";
import VerticalLayout from "@src/layouts/VerticalLayout";
import RfidTool from "../../views/correctionTool/RfidTool";
import PrivateRoute from "@components/routes/PrivateRoute";
import HorizontalLayout from "@src/layouts/HorizontalLayout";
import RfidOrderExport from "../../views/rfidorder/ExportRfidOrder";
import RegularOrderExport from "../../views/order/RegularOrderExport";
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper";
import HourlyProductSales from "../../views/salesstats/HourlyProductSales";
import CashierShift from "../../views/shift";

const Home = lazy(() => import("../../views/Home"));
const Error = lazy(() => import("../../views/Error"));
const OrderList = lazy(() => import("../../views/order"));
const EventList = lazy(() => import("../../views/event"));
const SmartGate = lazy(() => import("../../views/smartgate"));
const TablesList = lazy(() => import("../../views/table"));
const AccessoriesList = lazy(() => import("../../views/accessories"));
const GroupList = lazy(() => import("../../views/group"));
const AdminList = lazy(() => import("../../views/admin"));
const RfCards = lazy(() => import("../../views/rfidcard"));
const TicketList = lazy(() => import("../../views/deals"));
const Login = lazy(() => import("../../views/auth/Login"));
const Register = lazy(() => import("../../views/Register"));
const ProductList = lazy(() => import("../../views/product"));
const CashierList = lazy(() => import("../../views/cashier"));
const PageNotFound = lazy(() => import("../../views/404.js"));
const KitchenList = lazy(() => import("../../views/kitchen"));
const RfIdOrders = lazy(() => import("../../views/rfidorder"));
const CategoryList = lazy(() => import("../../views/category"));
const LocationList = lazy(() => import("../../views/location"));
const LocationSales = lazy(() => import("../../views/salesstats"));
const RefundList = lazy(() => import("../../views/rfidcard/Refund"));
const CreateRfidCards = lazy(() => import("../../views/rfidcard/CreateRfidCards"));
const FreezedOrderList = lazy(() => import("../../views/freezeOrder"));
const CorrectionTool = lazy(() => import("../../views/correctionTool"));
const TicketDetail = lazy(() => import("../../views/deals/TicketDetail"));
const OrderDetails = lazy(() => import("../../views/order/OrderDetails"));
const UpdateProfile = lazy(() => import("../../views/admin/UpdateProfile"));
const UpdatePassword = lazy(() => import("../../views/admin/UpdatePassword"));
const CashierSales = lazy(() => import("../../views/salesstats/CashierSales"));
const ProductSales = lazy(() => import("../../views/salesstats/ProductSales"));
const FreezedOrderDetail = lazy(() => import("../../views/freezeOrder/FreezedOrderDetails"));

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />,
};

const TemplateTitle = "%s - POS Admin Panel";
const DefaultRoute = "/dashboard";

const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={DefaultRoute} />,
  },
  {
    path: "/dashboard",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: "blank",
      restricted: true,
      publicRoute: true,
    },
  },
  {
    path: "/register",
    element: <Register />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/password/update",
    element: <UpdatePassword />,
  },
  {
    path: "/admin",
    element: <UpdateProfile />,
  },
  {
    path: "/adminList",
    element: <AdminList />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/cashiers",
    element: <CashierList />,
  },
  {
    path: "/shifts",
    element: <CashierShift />,
  },
  {
    path: "/locations",
    element: <LocationList />,
  },
  {
    path: "/categories",
    element: <CategoryList />,
  },
  {
    path: "/groups",
    element: <GroupList />,
  },
  {
    path: "/kitchens",
    element: <KitchenList />,
  },
  {
    path: "/products",
    element: <ProductList />,
  },
  {
    path: "/events",
    element: <EventList />,
  },
  {
    path: "/smartgate",
    element: <SmartGate />,
  },
  {
    path: "/tables",
    element: <TablesList />,
  },
  {
    path: "/accessories",
    element: <AccessoriesList />,
  },
  {
    path: "/cards",
    element: <RfCards />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/refund",
    element: <RefundList />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/createRfid-cards",
    element: <CreateRfidCards />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/regularOrders",
    element: <OrderList />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/regularOrderExport",
    element: <RegularOrderExport />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/rfidOrdersexport",
    element: <RfidOrderExport />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/rfidOrders",
    element: <RfIdOrders />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/freezedOrders",
    element: <FreezedOrderList />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/orderDetails",
    element: <OrderDetails />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/freezeOrderDetails",
    element: <FreezedOrderDetail />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/salesReport",
    element: <LocationSales />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/cashierSales",
    element: <CashierSales />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/productsales",
    element: <ProductSales />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/hourlproductsales",
    element: <HourlyProductSales />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/ticketList",
    element: <TicketList />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/ticketDetails",
    element: <TicketDetail />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/productTool",
    element: <CorrectionTool />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/RfidTool",
    element: <RfidTool />,
    meta: {
      action: "manage",
      resource: "superadmin",
    },
  },
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "*",
    element: <PageNotFound />,
    meta: {
      layout: "blank",
      publicRoute: true,
    },
  },
];

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    } else {
      return {};
    }
  }
};

const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = [];

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false;
      if ((route.meta && route.meta.layout && route.meta.layout === layout) || ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)) {
        const RouteTag = route.meta && route.meta.publicRoute ? PublicRoute : PrivateRoute;
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false);
        }
        if (route.element) {
          const Wrapper = isObjEmpty(route.element.props) && isBlank === false ? LayoutWrapper : Fragment;
          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          );
        }
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical";
  const layouts = ["vertical", "horizontal", "blank"];
  const AllRoutes = [];

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);
    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    });
  });
  return AllRoutes;
};

export { DefaultRoute, TemplateTitle, Routes, getRoutes };
